import React, { useState, useEffect } from "react";
import "./App.css"; // Import the CSS file

const IntroAnimation = ({ onAnimationEnd }) => {
  const [fadeOut, setFadeOut] = useState(false); // State to control the fade-out

  useEffect(() => {
    // Set a timer to trigger the fade-out
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true); // Set fadeOut to true to apply the fade-out class
    }, 3500); // Start fading out after 3.5 seconds

    // Set another timer to hide the intro animation after the fade-out completes
    const hideTimer = setTimeout(() => {
      onAnimationEnd && onAnimationEnd(); // Call the onAnimationEnd callback to remove the intro
    }, 4000); // Complete the transition after 4 seconds

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(hideTimer);
    };
  }, [onAnimationEnd]);

  return (
    <div className={`intro-container ${fadeOut ? 'fade-out' : ''}`}>
      <div className="intro-text">
        MYK MOUR
      </div>
    </div>
  );
};

export default IntroAnimation;
