import React, { useState, useEffect } from 'react';
import './App.css';
import IntroAnimation from './IntroAnimation';

function App() {
  const [showIntro, setShowIntro] = useState(true); // Controls visibility of intro
  const [contentVisible, setContentVisible] = useState(false); // Controls fade-in for main content

  useEffect(() => {
    // This will hide the intro after 4 seconds and show the main content
    const timer = setTimeout(() => {
      setShowIntro(false); // Hide intro animation
      setContentVisible(true); // Show main content
    }, 4000); // 4 seconds

    return () => clearTimeout(timer); // Cleanup
  }, []);

  return (
    <div className="App">
      {showIntro && <IntroAnimation />} {/* Render the intro animation */}

      <div className={`container ${contentVisible ? 'fade-in' : ''}`}>
        <div className="profile">
          <img 
            src="logo.jpg" 
            alt="myk mour" 
            className="profile-image" 
          />
          <h1>MYK MOUR</h1>
          <p>Director & Performer</p>
        </div>

        <div className={`links ${contentVisible ? 'fade-in' : ''}`}>
          <a href="https://myk-mour.manyvids.com" className="button" target="_blank" rel="noopener noreferrer">
            ManyVids
          </a>
          <a href="https://sheer.com/mykmour" className="button" target="_blank" rel="noopener noreferrer">
            Sheer
          </a>
          <a href="https://x.com/mykmour" className="button" target="_blank" rel="noopener noreferrer">
            X
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
